
















































import { Component, Vue, Prop } from 'vue-property-decorator'
import Bangkok from '@/components/svg/Bangkok.vue'
import Cash from '@/components/svg/Cash.vue'
import KBank from '@/components/svg/KBank.vue'
import Krungsri from '@/components/svg/Krungsri.vue'
import KTB from '@/components/svg/KTB.vue'
import SCB from '@/components/svg/SCB.vue'
import Thanachart from '@/components/svg/Thanachart.vue'
import TMB from '@/components/svg/TMB.vue'
import UOB from '@/components/svg/UOB.vue'
import TTB from '@/components/svg/TTB.vue'
import GSB from '@/components/svg/GSB.vue'

@Component({
  components: {
    Bangkok,
    Cash,
    KBank,
    Krungsri,
    KTB,
    SCB,
    Thanachart,
    TMB,
    UOB,
    TTB,
    GSB,
  },
})
export default class extends Vue {
  @Prop({ default: '' }) readonly bank!: string
  @Prop() readonly active!: boolean
  get bankName() {
    return this.bank.toUpperCase()
  }
}
