

















































































































































































































































































































































import { Component, Vue, PropSync, Watch } from 'vue-property-decorator'
import { vxm } from '@/store'
import {
  TransactionName,
  CoinTransactionSubject,
} from '../../types/transaction'
import BankBtn from '@/components/Admin/BankBtn.vue'

@Component({
  computed: {
    onFetch: () => vxm.userTransaction.fetchStatus,
    subject: () => vxm.userTransaction.getSubject,
    user: () => vxm.userTransaction.userInfo,
    totalCoin: () => vxm.userTransaction.userTotalCoin,
  },
  components: {
    BankBtn,
  },
})
export default class extends Vue {
  @PropSync('show', { type: Boolean }) isShow!: boolean
  protected readonly subject!: CoinTransactionSubject
  protected readonly coinTypeList = [TransactionName.cvn]
  protected selectedCoinType = TransactionName.cvn
  protected topUpCoin = '0'
  protected showTopupMenu = false
  protected transferType = 'cash'
  protected transferData = {
    bankName: '',
    transferNumber: '',
    transferDate: new Date().toISOString().slice(0, 10),
    transferHour: new Date().getHours(),
    transferMinute: new Date().getMinutes(),
  }
  protected datePicker = false
  protected formatedDate = this.dateFormat(this.transferData.transferDate)
  protected onLoad = false
  protected shortcut = [
    {
      text: '12000(15)',
      value: '15',
      type: TransactionName.cvn,
      color: 'blue darken-1',
    },
    {
      text: '7200(9)',
      value: '9',
      type: TransactionName.cvn,
      color: 'blue darken-1',
    },
    {
      text: 'NEW/4000',
      value: '5',
      type: TransactionName.cvn,
      color: 'light-blue lighten-1',
    },
    {
      text: 'INVITE',
      value: '1',
      type: TransactionName.nf,
      color: 'red lighten-1',
    },
  ]
  protected confirmDialog = false

  protected hours = () => {
    const res: number[] = []
    for (let i = 0; i < 25; i++) {
      res.push(i)
    }
    return res
  }
  protected minutes = () => {
    const res: number[] = []
    for (let i = 0; i < 61; i++) {
      res.push(i)
    }
    return res
  }

  @Watch('transferData.transferDate')
  setFormatedDate(val: string) {
    this.formatedDate = this.dateFormat(val)
  }

  protected dateFormat(dateIn: string) {
    const [year, month, date] = dateIn.split('-')
    const thYear = `${Number(year) + 543}`.slice(-2)
    return `${date}/${month}/${thYear}`
  }
  get subjColor() {
    switch (this.subject) {
      case CoinTransactionSubject.math.toUpperCase():
        return 'math'
      case CoinTransactionSubject.physics.toUpperCase():
        return 'phys'
      case CoinTransactionSubject.chem.toUpperCase():
        return 'chem'
      case CoinTransactionSubject.english.toUpperCase():
        return 'engl'
      default:
        return ''
    }
  }
  get coinTypeColor() {
    switch (this.selectedCoinType) {
      case TransactionName.bt:
        return 'blue darken-1'
      case TransactionName.bc:
        return 'teal lighten-3'
      case TransactionName.yc:
        return 'amber darken-2'
      case TransactionName.cv:
        return 'red darken-2'
      case TransactionName.nf:
        return 'light-blue lighten-2'
      case TransactionName.cvn:
        return 'green accent-2'
      default:
        return ''
    }
  }
  protected close() {
    this.isShow = false
  }
  protected closeTopup() {
    this.showTopupMenu = false
  }
  protected changeCoinType(type: TransactionName) {
    this.selectedCoinType = type
    switch (type) {
      case TransactionName.bt:
        this.topUpCoin = '17'
        break
      case TransactionName.bc:
        this.topUpCoin = '12'
        break
      case TransactionName.yc:
        this.topUpCoin = '15'
        break
      default:
        break
    }
  }
  protected selectShortcut(type: TransactionName, value: string) {
    this.selectedCoinType = type
    this.topUpCoin = value
  }
  protected changeTransferType(type: string) {
    this.transferType = type
  }
  protected submit() {
    this.confirmDialog = true
  }
  protected async sendData() {
    this.onLoad = true
    this.confirmDialog = false
    const tfNumber =
      this.transferType === 'cash'
        ? `cash`
        : `${this.transferData.bankName} ${this.transferData.transferNumber}`
    const tfDate = new Date()
    const [year, month, date] = this.transferData.transferDate.split('-')
    tfDate.setDate(Number(date))
    tfDate.setMonth(Number(month) - 1)
    tfDate.setFullYear(Number(year))
    tfDate.setHours(this.transferData.transferHour)
    tfDate.setMinutes(this.transferData.transferMinute)
    const params = {
      amount: Number(this.topUpCoin),
      name: this.selectedCoinType,
      transactionNumber: tfNumber,
      transactionDate: tfDate.toISOString(),
    }
    await vxm.userTransaction.createTopupTransaction(params)
    this.isShow = false
    this.onLoad = false
    this.topUpCoin = '0'
    this.selectedCoinType = TransactionName.bt
    this.transferData = {
      bankName: '',
      transferNumber: '',
      transferDate: new Date().toISOString().slice(0, 10),
      transferHour: new Date().getHours(),
      transferMinute: new Date().getMinutes(),
    }
    this.transferType = 'cash'
  }
}
